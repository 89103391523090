// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;



var lualBodyClasses = ["snowdrop-2022", "snowdrop-2023", "light-up-a-life-2022", "light-up-a-life-2023", "PostSystemCategory_snow-appeal-style", "PostSystemCategory_light-up-a-life-appeal-style", "PostCategory_tea-appeal"];
var visualAppealsLoadMore = true;
var visualDonationCount = 20;
var visualAppealsCustomHeightValues = {
  "PostCategory_tea-appeal": {
    heightSm: "-1040",
    heightMd: "-1120",
    heightXl: "-1300"
  }
}

// Remove empty div in headerText div
if (($('body[class*="PostCategory_tea-appeal"]').length = 1)) {
  $('.headerText div').each(function () {
    if ($.trim($(this).html()) === '') {
      $(this).remove();
    }
  });
}

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

$('.carouselButtons, .carouselControls').wrapAll('<div class="carouselControlsAndButtons">');

$(window).on("load resize scroll", function () {
  $('document').ready(function () {
    $offset = $('.carouselSlideDetail').first().offset().left;
    $('.carouselControlsAndButtons').css('margin-left', $offset);
  })
  if ($('body').hasClass('homepage')) {
    $('.homefeaturecategory-homeboxquickgiving .donationAmount img').each(function () {
      $(this).css('height', $(this).css('width'));
    })
  }

  // Set feed item image heights
  $('.homeFeed:not(.homeFeedBox1):nth-child(even)').each(function () {
    let height = $('.feedItem:first-child .home-feed-image-link img').css('height');
    $(this).find('.feedItem:not(:first-child) .home-feed-image-link img').css('height', height);
  })
  $('.homeFeed:not(.homeFeedBox1):nth-child(odd)').each(function () {
    let height = $('.feedItem:last-child .home-feed-image-link img').css('height');
    $(this).find('.feedItem:not(:last-child) .home-feed-image-link img').css('height', height);
  })
});

// Fight with fresco to reorder this element properly
// Yes, I hate that I have to do this
$('document').ready(function () {
  if ($('body').hasClass('homepage')) {
    $('.homefeaturecategory-homeboximpactstats').insertAfter('.post0f854a0b0823466285de0bf1eaf32cec')
    $('.homefeaturecategory-homeboxquickgiving').insertAfter('.homefeaturecategory-homeboximpactstats')
  }
})

$('document').ready(function () {
  if ($('body').hasClass('homepage')) {
    // Resize donation amount images so they are square
    // (Fresco has this built-in, but uses the carousel image dimensions)
    // Also remove unnecessary quick giving elements
    $('.homefeaturecategory-homeboxquickgiving label img').each(function () {
      $(this).attr('src', $(this).attr('src').replace('width=300&height=90', 'width=523&height=523'))
    })
    $('.homefeaturecategory-homeboxquickgiving*').wrap('<div class="homeQuickGivingOuter"></div>')
    $(
      '#bodyForm > section > section.homeFeaturesWrapper > div.homeQuickGivingOuter > section > div > span > div > fieldset > span:nth-child(1), #bodyForm > section > section.homeFeaturesWrapper > div.homeQuickGivingOuter > section > div > span > div > fieldset > span.regularPaymentLabel, #bodyForm > section > section.homeFeaturesWrapper > div.homeQuickGivingOuter > section > div > span > div > fieldset > input'
    ).remove();

    // Add read more buttons to feed box 1 items
    $('.homeFeedBox1 .feedItem').each(function () {
      $('<a class="cta-button ghost">Read more</a>').insertAfter($(this).find(".feedItemDetailsWrapper > p:last-of-type"))
    })
  }
  if ($('body').hasClass('listing-with-feature')) {
    $('.carouselSlide').each(function () {
      $('.headerText .breadcrumbWrapper').clone().insertBefore($(this).find('.carouselSlideHeading'));
    })
  }
  if ($('body[class*="PostBody"], body.donate').length > 0 && $('body').hasClass('page-has-banner') || $('body').hasClass('listing-without-feature')) {
    $('.carouselSlide img').wrap('<a class="carouselSlideTitle"></a>');
    $('<div class="carouselDetailWrapper"><div class="carouselSlideDetail "></div></div>').insertAfter('.carouselSlideTitle');
    $('.breadcrumbWrapper, .title').appendTo('.carouselSlideDetail');
    $('.carouselSlideTitle img').addClass('loaded');
  }

  // Eject sidebar from content block
  if ($('.associatedLatestPosts').length > 0) {
    $('.associatedLatestPosts').insertAfter('.contentBlockWrapper:not(.accordianBody)');
    if ($('.associatedBox').length > 0) {
      $('<div class="associatedBoxWrapper"><h3>Featured post</h3></div>').insertBefore('.associatedLatestPosts');
      $('.associatedBox').appendTo('.associatedBoxWrapper');
      $('.associatedBox').each(function () {
        $(this).find('h2, p').wrapAll('<div class="listedPostText"></div>');
        $(this).find('> a').addClass('listed-post-image-link');
      })
    }
  }
})

if (($('body[class*="PostBody"]').length = 1)) {
  var blockquotes = document.querySelectorAll("blockquote");
  for (var i = 0; i < blockquotes.length; i++) {
    if (blockquotes[i].innerHTML.includes("quote-attribute")) {
      blockquotes[i].classList.add("contains-attribution");
    }
  }
}

